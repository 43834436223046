import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import CTAButton from '../../components/CTAButton/CTAButton';
import CtaBlock from '../../components/CtaBlock';

const ProjectLayout = ({children}) => {
  return (
    <React.Fragment>
      {children}
      <CtaBlock title="Planning a field construction?">
        <CTAButton
          inverted
          ctaHref="/contact"
          ctaText="Call Us"
        />
      </CtaBlock> 
    </React.Fragment>
  )
}

export default withStyles({ withTheme: true })(ProjectLayout);
