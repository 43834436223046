import React from 'react'
import { Grid } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import InnerImage from '../../components/InnerImage/innerImage'
import CenterContentMain from '../../components/CenterContentMain';
import CenterContentNarrow from '../../components/CenterContentNarrow';
import SectionTitle from '../../components/SectionTitle';
import SectionIcons from '../../components/singleproject/sectionIcons';
import ProjectIntroBlock from '../../components/singleproject/ProjectIntroBlock';

const styles = theme =>
  createStyles({
    introTop: {
      padding: '35px 0',
      [theme.breakpoints.up(767)]: {
        padding: '45px 0',
      },
      maxWidth: '850px',
      margin: '0 auto'
    },
    
    iconsWrapper: {
      justifyContent: 'center',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
     },
  });

const ProjectHighlight = ({heroImage, projectTitle = '', projectSubtitle = '', serviceShortInfo = [], serviceDescription = '', serviceListItems = [], classes}) => {
  return (
    <React.Fragment>
      <InnerImage 
        image={{className: classes.img, src: heroImage}}
      />
      <CenterContentMain>
        <Grid
          container
          direction="column"
          className={classes.introTop}
        >
          <SectionTitle
            title={projectTitle}
            subTitle={projectSubtitle} 
          /> 
          <Grid
            container
            className={classes.iconsWrapper}
          >
            {serviceShortInfo.map(({icon, type, text}, i) => (
              <SectionIcons
                key={i}
                iconImage={icon}
                textAbove={type}
                textBelow={text}
              />
            ))}
          </Grid>
        </Grid>
      </CenterContentMain>
      <CenterContentNarrow>
        <ProjectIntroBlock 
          blockItems={serviceListItems} 
          blockText={serviceDescription}
        />
      </CenterContentNarrow>
    </React.Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(ProjectHighlight);
