import React from 'react'
import { Grid } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CenterContentMain from '../../components/CenterContentMain';
import FullWidthCarouselSlider from '../../components/Carousel/FullWidthCarouselSlider';
import SectionTitle from '../../components/SectionTitle';

const styles = theme =>
  createStyles({
    sliderWrap: {
      marginTop: 20,
      [theme.breakpoints.up(767)]: {
        marginTop: 45
      },
    }
  });

const ProjectTransformation = ({title='The Transformation', transformationSlider = [], classes}) => {
  return (
    <React.Fragment>
      <CenterContentMain>
        <SectionTitle
          title={title}
        />
        <Grid
        container
        className={classes.sliderWrap}
        >
          <FullWidthCarouselSlider largeImage items={transformationSlider} />
        </Grid>
      </CenterContentMain>
    </React.Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(ProjectTransformation);
