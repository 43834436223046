import React from 'react'
import { Grid } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import InnerImage from '../../components/InnerImage/innerImage'
import CenterContentMain from '../../components/CenterContentMain';
import CenterContentNarrow from '../../components/CenterContentNarrow';
import TitleSmaller from '../../components/TitleSmaller';
import ProcessText from '../../components/singleproject/processText';
import ListItemsBlock from '../../components/ListItemsBlock';

const styles = theme =>
  createStyles({
    imageSection: {
       [theme.breakpoints.up(960)]: {
          padding: '20px 0px 80px 0',
      },
     },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
     },

    twoColumnsWrap: {
      alignItems: 'flex-start',
      paddingBottom: 50,
      flexWrap: 'wrap',
      paddingTop: 10,
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingBottom: 62,
        paddingTop: 0,
      },
     },
 
    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 120px 40px 0'
      },
     },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnImage: {
      maxHeight: '506px',
      overflow: 'hidden',
      [theme.breakpoints.up(767)]: {
        maxHeight: '560px'
      },
    },
  });

const ProjectFeature = ({featureImage, keyProcesses = [], featureTitle = '', featureHighlights = [], featureCtaText = 'Learn More', featureCtaHref = '/', featureThumbnail, classes}) => {
  return (
    <React.Fragment>
      <CenterContentMain>
        <Grid
          container
          className={classes.imageSection}
        >
          <InnerImage
            image={{ className: classes.img, src: featureImage }}
          ></InnerImage>

        </Grid>
      </CenterContentMain>
      <CenterContentNarrow>
        <Grid
          container
          direction="column"
          className={classes.twoColumnsWrap}
        >
          <Grid
            container
            direction="column"
            className={classes.leftColumnWrap}
          >
            <TitleSmaller
              title="Key Processes"
            ></TitleSmaller>

            {keyProcesses.map(process => (
            <ProcessText
              key={process.title}
              processTitle={process.title}
              processDesc={process.desc}
            />
            ))}
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.rightColumnWrap}
            >
            <ListItemsBlock
              title={featureTitle}
              text={featureHighlights}
              ctaText={featureCtaText}
              ctaHref={featureCtaHref}
              inverted
            />
            {featureThumbnail && 
              <Grid
                container
                className={classes.rightColumnImage}
              >
                <InnerImage
                  image={{ className: classes.img, src: featureThumbnail }} 
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </CenterContentNarrow>
    </React.Fragment>
  )
}

export default withStyles(styles, { withTheme: true })(ProjectFeature);
