
import React from 'react';
// import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const styles = theme =>
  createStyles({
    introBlockWrap: {
      [theme.breakpoints.up(767)]: {
        paddingBottom: '40px',
      },
      [theme.breakpoints.up(960)]: {
        flexDirection:'row',
        alignItems: 'center'
      }
    },

    blockWrap: {
      backgroundColor: theme.palette.grey.main,
      padding: '50px',
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up(767)]: {
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: '40px 55px',
      },
      [theme.breakpoints.up(960)]: {
        flex: '0 0 57%',
        maxWidth: '57%',
      },
    },

    blockListWrap: {
      alignItems: 'flext-start',
      padding: '15px 40px',
      justifyContent: 'center',
      marginTop: 40,
      marginBottom: 40,
      [theme.breakpoints.up(767)]: {
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 0,
        padding: '40px'
      },
      [theme.breakpoints.up(960)]: {
        flex: '0 0 43%',
        maxWidth: '43%',
        paddingLeft: '70px',
      },
    },

    blockText: {
      fontSize: 30,
      lineHeight: '36px',
      fontWeight: 600,
      [theme.breakpoints.up(767)]: {
        fontSize: 38,
        lineHeight: '42px',
        letterSpacing: '1px'
      },
    },

    listText: {
      fontWeight: 400,
      lineHeight: '36px',
      paddingLeft: 10,
      [theme.breakpoints.up(960)]: {
        paddingLeft: 0
      },
    }

  });

const IntroBlock = ({
  classes,
  blockItems = [],
  blockText = ''
  
}) => {

  return (
  <Grid
    container
    direction="column"
    className={classes.introBlockWrap}
  >
  {blockText &&   
    <Grid
      container
      className={classes.blockWrap}
      direction="column"
    >
      <Typography
        variant="body2"
        color= "primary"
        className={classes.blockText}
      >
        {blockText}
      </Typography>
    </Grid>
  }
  <Grid
    container
    className={classes.blockListWrap}
    direction="column"
  >   
    {blockItems.map(({value, text}, i) =>       
      <Typography
        key={i}
        variant="body1"
        color= "primary"
        className={classes.listText}
      >
        <strong style={{ fontWeight: '600' }}>{value}</strong>{' '}
        {text} 
      </Typography>)}
  </Grid>
</Grid>


  );
};

  export default withStyles(styles, { withTheme: true })(IntroBlock);